import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SideMenu from '../../extra/SideMenu';
import { GlobalContext } from '../../context/AppContext';
import { check_dt20, start_dt20 } from './js/refresh';
import MarketBets from '../../allBets/MarketBets';
import { startResult_dt20, runresult_dt20 } from './js/result';
import ResultDt from '../modals/ResultDt';
import { startSettle_dt20, runSettle_dt20 } from './js/settle';
import { 
cancelBet_dt20,
minusAmt_dt20,
plusAmt_dt20,
changeAmt_dt20,
stakeBet_dt20,
betSend_dt20,
oddsClick_dt20,
allSid_dt20,
pls_dt20
} from './js/betting';

const Dt20 = () => {

const [load_dt20, setLoad_dt20] = useState(true);
const [currdt20, setCurrDt20] = useState([]);
const { customer } = GlobalContext();

useEffect(() => {
async function fetchDt20() {
try {
const getData = await axios.get('/server/dt20_Data');
const resData = await getData.data;
if(resData.data !== undefined){
resData.data.t2.forEach(element => {
var data = {
sid : element.sid,
nation : element.nation
}

const findInd = allSid_dt20.findIndex((o) => o.sid === element.sid && o.nation === element.nation);
if(findInd === -1){
allSid_dt20.push(data);
}
});
setLoad_dt20(false);
setCurrDt20(resData);
}
else{
setLoad_dt20(true);
setCurrDt20([]);
}
} catch (error) {
console.log('frontend casino_dt20_data error : ' + error);
}
}

fetchDt20();
},[]);

useEffect(() => {
check_dt20();
runresult_dt20();
startSettle_dt20();
pls_dt20(currdt20.data !== undefined ? currdt20.data.t1[0].mid : 0,'dt20');
return () => {
clearInterval(start_dt20);
clearInterval(startResult_dt20);
clearInterval(runSettle_dt20);
}
},[currdt20]);

if(load_dt20){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='game-body'>
<div className='game-contents'>

{
currdt20.data !== undefined &&
<div className='casinoGame_wrap'>
<div className='headerCasGame'>
<div className='col'>
<span className='casGameTitle'>DT 20 20</span>
</div>
<div className='col'>
<span className='casRoundId'>mid : <span id='roundId_dt20'>{currdt20.data.t1[0].mid}</span></span>
</div>
</div>

<div className='videoWrapCas'>
<div className='liveCards'>
<div className='col'>
<span className='cardTeam'>cards</span>
<div className='teamLiveCards'>
<img id='card1_dt20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(currdt20.data.t1[0].c1.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card2_dt20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(currdt20.data.t1[0].c2.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div>    
</div>   
<div className='countDownWrap'>
<div className='countDown'></div> 
<div className='countTxt'><span id='autoTime_dt20'>{currdt20.data.t1[0].autotime}</span></div>   
</div> 
<div className='frameWrap'>
<iframe src='https://alpha-n.qnsports.live/route/ash.php?id=3035' title='casino video'></iframe>
</div>
</div>

<div className='casDataWrap'>

<div style={{display:'none'}} className='placeBetCasino' id='placeBetCasino_dt20'>
<div style={{padding:'0'}} colSpan='3'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id='betMsgWrapCas_dt20'>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id='bet_msgCas_dt20'></span>
</div>
<div className='bet-input-row'>
<span style={{display:'none'}} id='selBetType_dt20'>back</span>
<span style={{display:'none'}} id='sec_dt20'>match_odds</span>
<span style={{display:'none'}} id='sport_dt20'>dt20</span>
<span style={{display:'none'}} id='teamCas_dt20'></span>
<span style={{display:'none'}} id='teamSid_dt20'></span>
<span style={{display:'none'}} id='userCurr_dt20'>{customer.length > 0 ? customer[0].currency : 'inr'}</span>
<span style={{display:'none'}} id='eventid_dt20'>{currdt20.data.t1[0].mid}</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet_dt20()}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id='bet_rate_dt20' placeholder='0' disabled />
<span>+</span>
</div>

<div>
<span onClick={() => minusAmt_dt20()}>-</span>
<input type='number' id='bet_amount_dt20' placeholder='0' onChange={(e) => changeAmt_dt20(e.target.value)} />
<span onClick={() => plusAmt_dt20()}>+</span>
</div>

<div className='place'>
<button onClick={() => betSend_dt20()} className='' id='betsPlace_dt20'>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id='betColBet_dt20'>
<button onClick={() => stakeBet_dt20(customer.length > 0 ? customer[0].stake1 : 100)}>{customer.length > 0 ? customer[0].stake1 : 100}</button>
<button onClick={() => stakeBet_dt20(customer.length > 0 ? customer[0].stake2 : 200)}>{customer.length > 0 ? customer[0].stake2 : 200}</button>
<button onClick={() => stakeBet_dt20(customer.length > 0 ? customer[0].stake3 : 500)}>{customer.length > 0 ? customer[0].stake3 : 500}</button>
<button onClick={() => stakeBet_dt20(customer.length > 0 ? customer[0].stake4 : 1000)}>{customer.length > 0 ? customer[0].stake4 : 1000}</button>
<button onClick={() => stakeBet_dt20(customer.length > 0 ? customer[0].stake5 : 2000)}>{customer.length > 0 ? customer[0].stake5 : 2000}</button>
<button onClick={() => stakeBet_dt20(customer.length > 0 ? customer[0].stake6 : 5000)}>{customer.length > 0 ? customer[0].stake6 : 5000}</button>
<button onClick={() => stakeBet_dt20(customer.length > 0 ? customer[0].stake7 : 10000)}>{customer.length > 0 ? customer[0].stake7 : 10000}</button>
<button onClick={() => stakeBet_dt20(customer.length > 0 ? customer[0].stake8 : 25000)}>{customer.length > 0 ? customer[0].stake8 : 25000}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet_dt20()}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend_dt20()} className='' id='betsPlace2_dt20'>place bet</button>
</div> 
</div>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>dragon / tiger </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[0].sid}>{currdt20.data.t2[0].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[0].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[0].sid,currdt20.data.t2[0].nation,currdt20.data.t2[0].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[0].sid}>{currdt20.data.t2[0].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[0].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[0].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[1].sid}>{currdt20.data.t2[1].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[1].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[1].sid,currdt20.data.t2[1].nation,currdt20.data.t2[1].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[1].sid}>{currdt20.data.t2[1].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[1].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[1].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>tie / pair </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[2].sid}>{currdt20.data.t2[2].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[2].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[2].sid,currdt20.data.t2[2].nation,currdt20.data.t2[2].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[2].sid}>{currdt20.data.t2[2].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[2].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[2].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[3].sid}>{currdt20.data.t2[3].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[3].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[3].sid,currdt20.data.t2[3].nation,currdt20.data.t2[3].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[3].sid}>{currdt20.data.t2[3].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[3].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[3].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>d odd / even </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[4].sid}>{currdt20.data.t2[4].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[4].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[4].sid,currdt20.data.t2[4].nation,currdt20.data.t2[4].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[4].sid}>{currdt20.data.t2[4].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[4].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[4].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[5].sid}>{currdt20.data.t2[5].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[5].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[5].sid,currdt20.data.t2[5].nation,currdt20.data.t2[5].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[5].sid}>{currdt20.data.t2[5].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[5].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[5].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>d red / black </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[6].sid}>{currdt20.data.t2[6].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[6].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[6].sid,currdt20.data.t2[6].nation,currdt20.data.t2[6].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[6].sid}>{currdt20.data.t2[6].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[6].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[6].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[7].sid}>{currdt20.data.t2[7].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[7].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[7].sid,currdt20.data.t2[7].nation,currdt20.data.t2[7].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[7].sid}>{currdt20.data.t2[7].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[7].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[7].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>dragon cards </span></div>
<div className='dtRow'>
<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[8].sid}>{currdt20.data.t2[8].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[8].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[8].sid,currdt20.data.t2[8].nation,currdt20.data.t2[8].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[8].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/1.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[8].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[8].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[9].sid}>{currdt20.data.t2[9].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[9].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[9].sid,currdt20.data.t2[9].nation,currdt20.data.t2[9].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[9].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/2.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[9].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[9].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[10].sid}>{currdt20.data.t2[10].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[10].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[10].sid,currdt20.data.t2[10].nation,currdt20.data.t2[10].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[10].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/3.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[10].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[10].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[11].sid}>{currdt20.data.t2[11].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[11].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[11].sid,currdt20.data.t2[11].nation,currdt20.data.t2[11].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[11].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/4.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[11].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[11].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[12].sid}>{currdt20.data.t2[12].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[12].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[12].sid,currdt20.data.t2[12].nation,currdt20.data.t2[12].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[12].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/5.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[12].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[12].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[13].sid}>{currdt20.data.t2[13].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[13].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[13].sid,currdt20.data.t2[13].nation,currdt20.data.t2[13].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[13].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/6.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[13].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[13].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[14].sid}>{currdt20.data.t2[14].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[14].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[14].sid,currdt20.data.t2[14].nation,currdt20.data.t2[14].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[14].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/7.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[14].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[14].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[15].sid}>{currdt20.data.t2[15].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[15].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[15].sid,currdt20.data.t2[15].nation,currdt20.data.t2[15].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[15].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/8.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[15].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[15].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[16].sid}>{currdt20.data.t2[16].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[16].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[16].sid,currdt20.data.t2[16].nation,currdt20.data.t2[16].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[16].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/9.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[16].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[16].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[17].sid}>{currdt20.data.t2[17].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[17].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[17].sid,currdt20.data.t2[17].nation,currdt20.data.t2[17].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[17].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/10.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[17].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[17].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[18].sid}>{currdt20.data.t2[18].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[18].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[18].sid,currdt20.data.t2[18].nation,currdt20.data.t2[18].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[18].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/11.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[18].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[18].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[19].sid}>{currdt20.data.t2[19].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[19].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[19].sid,currdt20.data.t2[19].nation,currdt20.data.t2[19].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[19].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/12.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[19].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[19].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[20].sid}>{currdt20.data.t2[20].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[20].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[20].sid,currdt20.data.t2[20].nation,currdt20.data.t2[20].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[20].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/13.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[20].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[20].sid}>0</span>
</div>

</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>t odd / even </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[21].sid}>{currdt20.data.t2[21].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[21].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[21].sid,currdt20.data.t2[21].nation,currdt20.data.t2[21].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[21].sid}>{currdt20.data.t2[21].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[21].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[21].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[22].sid}>{currdt20.data.t2[22].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[22].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[22].sid,currdt20.data.t2[22].nation,currdt20.data.t2[22].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[22].sid}>{currdt20.data.t2[22].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[22].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[22].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>t red / black </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[23].sid}>{currdt20.data.t2[23].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[23].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[23].sid,currdt20.data.t2[23].nation,currdt20.data.t2[23].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[23].sid}>{currdt20.data.t2[23].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[23].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[23].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[24].sid}>{currdt20.data.t2[24].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[24].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[24].sid,currdt20.data.t2[24].nation,currdt20.data.t2[24].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[24].sid}>{currdt20.data.t2[24].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[24].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[24].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>tiger cards </span></div>
<div className='dtRow'>
<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[25].sid}>{currdt20.data.t2[25].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[25].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[25].sid,currdt20.data.t2[25].nation,currdt20.data.t2[25].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[25].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/1.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[25].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[25].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[26].sid}>{currdt20.data.t2[26].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[26].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[26].sid,currdt20.data.t2[26].nation,currdt20.data.t2[26].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[26].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/2.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[26].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[26].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[27].sid}>{currdt20.data.t2[27].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[27].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[27].sid,currdt20.data.t2[27].nation,currdt20.data.t2[27].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[27].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/3.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[27].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[27].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[28].sid}>{currdt20.data.t2[28].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[28].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[28].sid,currdt20.data.t2[28].nation,currdt20.data.t2[28].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[28].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/4.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[28].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[28].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[29].sid}>{currdt20.data.t2[29].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[29].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[29].sid,currdt20.data.t2[29].nation,currdt20.data.t2[29].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[29].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/5.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[29].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[29].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[30].sid}>{currdt20.data.t2[30].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[30].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[30].sid,currdt20.data.t2[30].nation,currdt20.data.t2[30].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[30].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/6.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[30].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[30].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[31].sid}>{currdt20.data.t2[31].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[31].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[31].sid,currdt20.data.t2[31].nation,currdt20.data.t2[31].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[31].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/7.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[31].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[31].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[32].sid}>{currdt20.data.t2[32].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[32].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[32].sid,currdt20.data.t2[32].nation,currdt20.data.t2[32].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[32].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/8.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[32].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[32].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[33].sid}>{currdt20.data.t2[33].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[33].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[33].sid,currdt20.data.t2[33].nation,currdt20.data.t2[33].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[33].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/9.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[33].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[33].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[34].sid}>{currdt20.data.t2[34].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[34].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[34].sid,currdt20.data.t2[34].nation,currdt20.data.t2[34].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[34].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/10.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[34].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[34].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[35].sid}>{currdt20.data.t2[35].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[35].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[35].sid,currdt20.data.t2[35].nation,currdt20.data.t2[35].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[35].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/11.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[35].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[35].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[36].sid}>{currdt20.data.t2[36].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[36].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[36].sid,currdt20.data.t2[36].nation,currdt20.data.t2[36].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[36].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/12.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[36].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[36].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[37].sid}>{currdt20.data.t2[37].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[37].sid} onClick={() => oddsClick_dt20(currdt20.data.t2[37].sid,currdt20.data.t2[37].nation,currdt20.data.t2[37].rate)}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[37].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/13.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[37].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[37].sid}>0</span>
</div>

</div>
</div>

</div>

<div className='casinoResWrap'>
<div className='casinoResTitle'><span>last result</span></div>
<div className='allResCas' id='allResCas_dt20'></div>
</div>

<MarketBets eventid={currdt20.data !== undefined ? currdt20.data.t1[0].mid : 0} />
</div>
}

</div>
</div>
</div>
</div>
</div>
</div>

<ResultDt />
</>
)
}

export default Dt20;
