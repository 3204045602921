import React from 'react';
import $ from 'jquery';

const ResultDt = () => {

const closeDetResdt = () => {
$('#casResModaldt').hide();
}

return (
<>
<div className='casResModal' id='casResModaldt'>
<div className='casResCont'>
<div className='casResHead'><span>details result</span> <i className='fa fa-close' onClick={closeDetResdt}></i></div>
<div className='casResBody'>

<div className='casDetResLoaddt'>
<span></span>
</div>

<div className='resCasCondt'>
<div className='cardsRow'>
<div className='col'>
<span className='cardTeamCas'>dragon</span>
<div className='cardsImg'>
<img id='img1dt' alt='card_img' />
</div>
</div>
<div className='col'>
<span className='cardTeamCas'>tiger</span>
<div className='cardsImg'>
<img id='img2dt' alt='card_img' />
</div>
</div>
</div>

<div className='casResWin'>
<span className='casRestxt'>winner : <span id='winCasdt'></span></span>
<span className='casRestxt'>dragon : <span id='dragonResdt'></span></span>
<span className='casRestxt'>tiger : <span id='tigerResdt'></span></span>
<span className='casRestxt'>mid : <span id='winCasMiddt'></span></span>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default ResultDt;
